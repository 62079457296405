import React from 'react';
import {Routes, Route } from 'react-router-dom';

import './elementos/estilos.css';

import Login from './elementos/login.js';
import Main from './elementos/main.js';

function App() {
  return (
    
      <Routes>
        <Route path="/" element={<Login/>}/>
        <Route path="/Main" element={<Main/>}/>
      </Routes>

    
  );
}

export default App;
