import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import gp from '../images/gp_logo.png';
import sr from '../images/sr_logo.png';
import sr_alta from '../images/sr_alta.png';

import db from '../firebase/firebaseConfig';
import { doc,getDocs,collection, setDoc } from "firebase/firestore";

import datas from './data.json';



const Login = () => {
  
  const [usuarios, setUsuarios] = useState([]);
  const [valor, setValor] = useState('');
  
  //
  useEffect(()=>{
   getUser();
   checkBlocker();
  },[]);

  //
  const getUser = async() => {
    const data = await getDocs(collection(db, 'registro'));
    const data2 = data.docs.map(doc =>{
      return {
        id: doc.id,
        ...doc.data()
      }
    });
    setUsuarios(data2);
    //console.log(data2);
    //console.log(usuarios);
  }

  //
  const checkBlocker = () =>{
    axios.get('https://gp-vivienda-default-rtdb.firebaseio.com/permisos/p1.json')
  .then(result => {
      //console.log(result.data.permiso);
      var acceso = result.data.permiso;
      if (acceso === "1"){
          document.getElementById("popup").style.display = "none";
      }else{
        console.log("Aún no inicia la transmisión");
      }
  }).catch(e => console.log('error', e));
  }

  //
  function verificarItem(){
    var x = datas.map(n => { 
     return n.correo; 
    }).indexOf(valor);

    if (valor !== ''){
      if(x === -1){
        alert("El correo no existe o es incorrecto");
        //console.log(x);
      }else if (x > -1){
        updateItem();
       //console.log(x);
      }
    }else{
      alert("Escribe el correo electrónico")
    }

  }
  
  //
  const updateItem = () =>{
    sessionStorage.setItem('permiso', '1');

    const ref = doc(db, "registro", valor);
    setDoc (ref, {
      correo: valor,
      entrada: 1,
      id: usuarios.length + 1
    }).then(goMain());
  }

  //
  const navigate = useNavigate();
  
  //
  function goMain(){
    navigate('/Main');
  }

  //
  function handleChange(e) {
    setValor(e.target.value.toLowerCase());
  }
   
  return (
   <div id="intro">

     <div id="popup">
        <div id="pop_center">
        <img className="logo" src={sr_alta} alt="Logo "/>
          <h1>BIENVENIDOS</h1>
          <p>La transmisión iniciará a las 3:50 PM</p>
          <p className="note">Actualiza tu navegador en ese momento</p>
        </div>
     </div>

     <div id="top">
       <div id="logo_top1">
          <img className="logo" src={gp} alt="Logo "/>
       </div>
       <div id="logo_top2">
          <img className="logo" src={sr} alt="Logo "/>
       </div>
     </div>
     
      <div id="center">
          <div id="indi">
            <h1>¡BIENVENIDO!</h1>
            <p>Registra tu correo corporativo para acceder al evento virtual</p>
          </div>
          <input type="email" id="texto" value={valor} onChange={handleChange} placeholder="correo"/>
          <button id="textoBtn" onClick={verificarItem}>ENTRAR</button>
      </div>

      <div id="footer">
        <p>SIRE 2021 / Powered by IVOQ.TV</p>
      </div>
   </div>

  );
}

export default Login;