import React, { useEffect, useState } from 'react';
import logo from '../images/logo.png';

const Main = () => {
  
 const [acceso, setAcceso] = useState(sessionStorage.getItem('permiso'));

  //
  useEffect(()=>{
    checkPermiso();
   },[]);

  //
  function checkPermiso(){
    
    if(acceso === null){
      console.log("Accediste sin registrar un correo");
      alert("No registró su correo");
    }else if (acceso === '1'){
      console.log("Correo registrado");
    }

  }
  
  return (
    <div className="areaMain">
      
      <div className="areaVideo">
       
        <div className="centerElement">
          <div id="logos">
            <img className="logo" src={logo} alt="Logo "/>
          </div>
          <div className="clear"></div>
          <div id="vid">
            <iframe src="https://vimeo.com/event/1872407/embed" title="video" frameBorder="0" allow="autoplay; picture-in-picture; fullscreen" allowFullScreen></iframe>
          </div>
          
        </div>

      </div>

      <div className="areaChat">
          <iframe src="https://vimeo.com/event/1872407/chat/" width="100%" height="100%" title="chat" frameBorder="0"></iframe>
      </div>
      
   </div>
  );
  
}

export default Main;